<template>
  <v-dialog v-model="show" width="500" persistent>
    <v-card>
      <v-card-title>
        <span>Confirmar</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('close')">
          <v-icon small>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <p>
          ¿Estás seguro que deseas eliminar el comentario? Esta acción no se
          puede revertir.
        </p>
        <div class="mt-3 d-md-flex justify-end">
          <v-btn
            class="mb-2"
            color="secondary"
            outlined
            :block="$vuetify.breakpoint.smAndDown"
            :class="{ 'mr-2': $vuetify.breakpoint.mdAndUp }"
            @click="$emit('close')"
          >
            <span>No, cancelar</span>
          </v-btn>
          <v-btn
            class="mb-2"
            :block="$vuetify.breakpoint.smAndDown"
            color="primary"
            :loading="loading"
            @click="deleteComment"
          >
            <v-icon small class="mr-2">mdi-delete</v-icon>
            <span>Sí, eliminar</span>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import serverRequestMixin from "../../mixins/serverRequest.mixin";

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    commentId: {
      type: String,
    },
  },

  mixins: [serverRequestMixin],

  data() {
    return {
      error: null,
      loading: false,
    };
  },

  methods: {
    async deleteComment() {
      this.loading = true;
      try {
        await this.deleteRequest(`/comments/${this.commentId}`);
        this.$emit("commentDeleted");
      } catch (error) {
        this.error =
          error.response?.data?.message ||
          "Error inesperado, contactar con soporte";
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
